// Fetch builder is a simple wrapper around an HTTPResponse + Promise
// Allowing us to reuse it through multiple projects without having to rework the fetching process everytime

interface HttpResponse<T> extends Response {
    parsedBody?: T;
    rawBlob?: Blob;
}

async function httpAPI<T>(request: RequestInfo):Promise<HttpResponse<T>> {

    const response: HttpResponse<T> = await fetch(request);

    try {
        response.parsedBody = await response.json();
    } catch (e) {
        throw new Error(response.statusText);
    }
    return response;
}

export async function APIGet<T>(
    path: string,
    args = {
        method: "get",
    }
): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        headers: []
    }

    return await httpAPI<T>(new Request(path, req));
}
