import React, {ReactNode} from 'react';
import './App.css';
import {Avatar, Button, ButtonBase, Container, Grid, Paper, Typography} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import DirectionsIcon from '@mui/icons-material/Directions';
import WebIcon from '@mui/icons-material/Web';
import {AppConfig} from "./ifaces";
import {APIGet} from "./fetchBuilder";
import PaperWithLabel from "./PaperWithLabel";

const {REACT_APP_RESOLVER_URL} = process.env;

// redirectToResolver404 will redirect the user to our regular 404 page
const redirectToResolver404 = () => {
    // This URL will generate a 404 on the resolver
    // window.location.href = "https://qrcode.link/a/";
}

function App() {

    // loading is used in order to display content once AppConfig payload has been fetched
    // it should **always** be present in our code
    const [loading, setLoading] = React.useState(true);

    // appConfig is used to store the payload received into a var
    // it should **always** be of type <AppConfig | null>
    const [appConfig, setAppConfig] = React.useState<AppConfig | null>(null);

    // Init your app using an initial React.useEffect in order to fetch our payload
    React.useEffect(() => {

        // DEBUG: Local development
        // setAppConfig({
        //     first_name: "Vincent",
        //     last_name: "Biret",
        //     emails: {
        //         work: "XXX@email.unitag.io"
        //     },
        //     tels: {
        //         work: ""
        //     },
        //     url: 'https://unitag.io',
        //     addr: '',
        //     city: 'Brentwood',
        //     pc: 'CM14 5JR',
        //     country: 'United Kingdom',
        //     org: 'Unitag',
        //     job_title: 'CEO'
        // });
        //
        // setLoading(false);
        // return;
        // DEBUG END

        // Get the ID of the vcard from our window location
        const fragments = window.location.href.split("/");
        const id = fragments[fragments.length - 1];
        // Call resolver in the format
        // resolver endpoint + app specific prefix + id
        APIGet<AppConfig>(REACT_APP_RESOLVER_URL + "/vc/" + id).then((data) => {
            // If the data is well parsed then set state to response payload
            if (data.parsedBody !== undefined) {
                setAppConfig(data.parsedBody);
            } else {
                // If an error has occured or payload is empty / corrupted then redirect to resolver 404
                redirectToResolver404();
            }
        }).catch(() => {
            // If an error has occured or payload is empty / corrupted then redirect to resolver 404
            redirectToResolver404();
        }).finally(() => {
            // In both cases the app has finished loading, we can update the state accordingly
            setLoading(false);
        });
    }, []);

    const keyToText = (key: string) => {
        switch (key) {
            case "addr":
                return "Address";
            case "city":
                return "City";
            case "country":
                return "Country";
            case "emails.general":
                return "Email";
            case "emails.home":
                return "Email (Home)";
            case "emails.work":
                return "Email (Work)";
            case "tels.cell":
                return "Mobile";
            case "tels.work":
                return "Office";
            case "tels.home":
                return "Home";
            case "url":
                return "Website";

        }
    }

    const createSectionNode = (key: string, value: string) => {
        return (<Grid item sx={{width: '100%'}}>
            <PaperWithLabel label={keyToText(key)}>
                <Typography style={{paddingLeft: 10}}>
                    {value}
                </Typography>
            </PaperWithLabel>
        </Grid>)
    }

    const createEmailSectionNode = (key: string, value: string) => {
        return (<Grid item sx={{width: '100%'}}>
            <PaperWithLabel label={keyToText(key)}>
                <Typography style={{paddingLeft: 10}}>
                    <a href={"mailto:"+ value}>{value}</a>
                </Typography>
            </PaperWithLabel>
        </Grid>)
    }

    const createPhoneSection = (key: string, value: string) => {
        return (
            <Grid item sx={{width: '100%'}}>
                <PaperWithLabel label={keyToText(key)}>
                    <Typography style={{paddingLeft: 10}}>
                        <a href={"tel:"+ value}>{value}</a>
                    </Typography>
                </PaperWithLabel>
            </Grid>
        )
    }

    const createLinkSectionNode = (key: string, value: string) => {
        return (
            <Grid item sx={{width: '100%'}}>
                <PaperWithLabel label={keyToText(key)}>
                    <Typography style={{paddingLeft: 10}}>
                        <a href={value}>{value}</a>
                    </Typography>
                </PaperWithLabel>
            </Grid>
        )
    }

    const renderCard = () => {

        let sections: ReactNode[] = [];

        if (appConfig !== null) {
            if (appConfig.tels !== undefined) {
                if (appConfig.tels.cell !== undefined) { sections.push(createPhoneSection("tels.cell", appConfig.tels.cell)) }
                if (appConfig.tels.work !== undefined) { sections.push(createPhoneSection("tels.work", appConfig.tels.work)) }
                if (appConfig.tels.home !== undefined) { sections.push(createPhoneSection("tels.home", appConfig.tels.home)) }
            }

            if (appConfig.emails !== undefined) {
                if (appConfig.emails.general !== undefined) { sections.push(createEmailSectionNode("emails.general", appConfig.emails.general)) }
                if (appConfig.emails.home !== undefined) { sections.push(createEmailSectionNode("emails.home", appConfig.emails.home)) }
                if (appConfig.emails.work !== undefined) { sections.push(createEmailSectionNode("emails.work", appConfig.emails.work)) }
            }

            if (appConfig.url !== undefined) { sections.push(createLinkSectionNode("url", appConfig.url))}

            const nodeAddress = renderAddress();
            if (nodeAddress !== null) {
                sections.push(nodeAddress);
            }

            // Last note
            if (appConfig.note !== undefined) { sections.push(createSectionNode("note", appConfig.note))}

            // Download button
            sections.push(
                <Grid item sx={{width: '100%'}}>
                    <Button fullWidth>Download VCard</Button>
                </Grid>
                    )
        }

        return sections;
    }

    const renderAvatar = () => {

        if (appConfig !== null) {
            if (appConfig.first_name !== undefined && appConfig.last_name !== undefined) {
                return <Avatar sx={{ width: 100, height: 100, fontSize: 50, bgcolor: '#B4C74A' }} children={`${appConfig?.first_name[0]}${appConfig?.last_name[0]}`}/>

            } else if (appConfig.first_name !== undefined && appConfig.last_name === undefined) {
                return <Avatar sx={{ width: 100, height: 100, fontSize: 50, bgcolor: '#B4C74A' }} children={`${appConfig?.first_name[0]}`}/>
            }
            return <Avatar sx={{ width: 100, height: 100, fontSize: 60, bgcolor: '#B4C74A' }}><PersonIcon sx={{fontSize: 60}}/></Avatar>
        }
    }

    const renderName = () => {

        if (appConfig !== null) {
            if (appConfig.first_name !== undefined && appConfig.last_name !== undefined) {
                return <Typography sx={{fontSize: 24, fontWeight: 500}}>{appConfig.first_name} {appConfig.last_name}</Typography>

            } else if (appConfig.first_name !== undefined && appConfig.last_name === undefined) {
                return <Typography sx={{fontSize: 24, fontWeight: 500}}>{appConfig.first_name}</Typography>
            }
        }
        return null
    }

    const renderJob = () => {

        if (appConfig !== null) {
            if (appConfig.job_title !== undefined && appConfig.org !== undefined) {
                return <Typography sx={{fontSize: '1rem', fontWeight: 300, marginTop: -1}}>{appConfig.job_title} - {appConfig.org}</Typography>
            } else if (appConfig.job_title !== undefined && appConfig.org === undefined) {
                return <Typography sx={{fontSize: '1rem', fontWeight: 300, marginTop: -1}}>{appConfig.job_title}</Typography>
            } else if (appConfig.job_title === undefined && appConfig.org !== undefined) {
                return <Typography sx={{fontSize: '1rem', fontWeight: 300, marginTop: -1}}>{appConfig.org}</Typography>
            }
        }

        return null
    }

    const renderActions = () => {

        if (appConfig !== null) {

            // Count the number of items in order to find the size of the items
            // in the bar
            let actionsCount = 0
            if (appConfig.tels !== undefined) {
                actionsCount += 1
            }
            if (appConfig.emails !== undefined) {
                actionsCount += 1
            }
            if (appConfig.addr !== undefined && appConfig.city !== undefined) {
                actionsCount += 1
            }
            if (appConfig.url !== undefined) {
                actionsCount += 1
            }

            if (actionsCount === 0) {
                return null
            }

            // Divide the max size of an item in a Grid by the number of items detected
            const sizeItem = 12 / actionsCount

            return (
                <Grid container spacing={3}>
                    {appConfig.tels !== undefined ?
                        <Grid md={sizeItem} item sx={{textAlign: "center"}}>
                            <ButtonBase>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <PhoneIcon />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{fontSize: 10}}>
                                            Call
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ButtonBase>
                        </Grid>
                        :
                        null
                    }

                    {appConfig.emails !== undefined ?
                        <Grid md={sizeItem} item sx={{textAlign: "center"}}>
                            <ButtonBase>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <EmailIcon />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{fontSize: 10}}>
                                            Email
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ButtonBase>
                        </Grid>
                        :
                        null
                    }

                    {appConfig.addr !== undefined && appConfig.city !== undefined ?
                        <Grid md={3} item sx={{textAlign: "center"}}>
                            <ButtonBase href={"https://www.google.com/maps/search/?api=1&query=24%20Crescent%20Road%2CBrentwood%2CCM145JR%2CGB"}>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <DirectionsIcon />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{fontSize: 10}}>
                                            Map
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ButtonBase>
                        </Grid>
                        :
                        null
                    }

                    {appConfig.url !== undefined ?
                        <Grid md={3} item sx={{textAlign: "center"}}>
                            <ButtonBase>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <WebIcon />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{fontSize: 10}}>
                                            Website
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ButtonBase>
                        </Grid>
                        :
                        null
                    }
                </Grid>
            )
        }

        return null
    }

    const renderAddress = () => {
        if (appConfig !== null) {
            if (appConfig.addr !== undefined || appConfig.pc !== undefined || appConfig.city !== undefined || appConfig.country !== undefined) {
                return (
                    <Grid item sx={{width: '100%'}}>
                        <PaperWithLabel label={"Address"}>
                            {appConfig.addr !== undefined ?
                                <Typography style={{paddingLeft: 10}}>{appConfig.addr}</Typography>
                                :
                                null
                            }
                            {appConfig.city !== undefined ?
                                <Typography style={{paddingLeft: 10}}>{appConfig.city}</Typography>
                                :
                                null
                            }
                            {appConfig.pc !== undefined ?
                                <Typography style={{paddingLeft: 10}}>{appConfig.pc}</Typography>
                                :
                                null
                            }
                            {appConfig.country !== undefined ?
                                <Typography style={{paddingLeft: 10}}>{appConfig.country}</Typography>
                                :
                                null
                            }
                        </PaperWithLabel>
                    </Grid>
                    )
            }
        }
        return null;
    }


    return (
        <div className="App">
            {loading ? null
                :
                // App Content logic here
                <Container maxWidth={"xs"} sx={{
                    paddingTop: 6
                }}>

                    {/* Avatar */}
                    <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
                        <Grid item>
                            {renderAvatar()}
                        </Grid>
                        <Grid item>
                            {renderName()}
                        </Grid>
                        <Grid item>
                            {renderJob()}
                        </Grid>
                        <Grid item>
                            {renderActions()}
                        </Grid>
                    </Grid>
                    <br/>

                    <Grid container justifyContent="flex-start" alignItems={"center"} spacing={3} style={{
                        textAlign: "left"
                    }}>
                        {renderCard()}
                    </Grid>
                </Container>
            }
        </div>
    );
}

export default App;
